// Modifies the options to include those set within the data-* attributes.
function getSwiperOptionAttributes (options, swiperElm) {
  var optionsAttributes = swiperElm.dataset

  if (optionsAttributes.hasOwnProperty('slidesperview')) {
    options.slidesPerView = parseInt(optionsAttributes.slidesperview)
  }
  if (optionsAttributes.hasOwnProperty('spacebetween')) {
    options.spaceBetween = parseInt(optionsAttributes.spacebetween)
  }
  if (optionsAttributes.hasOwnProperty('loop')) {
    options.loop = optionsAttributes.loop == 'true'
  }
  if (optionsAttributes.hasOwnProperty('disableautoplay')) {
    options.autoplay = false
  }

  return options
}

export function swiperDesktop () {
  // get all sliders, we need to loop them due to different settings + nav
  var swipers = document.querySelectorAll(
    '.swiper-container:not(.control):not(.mobile)'
  )
  swipers.forEach(function (el, index) {
    var closestSection = el.closest('section')
    var controls = closestSection.querySelector('.control')

    // Slider default settings
    var options = {
      speed: 600,
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true
      },
      navigation: {},
      thumbs: {}
    }

    // Slider attribute overrides
    options = getSwiperOptionAttributes(options, el)

    // init nav
    options.navigation = {
      nextEl: closestSection.querySelector('.swiper-next'),
      prevEl: closestSection.querySelector('.swiper-prev')
    }

    // maybe add gallery controls to the slider
    if (controls) {
      var controlOptions = {
        speed: 600,
        loop: true,
        slidesPerView: 4
      }

      controlOptions = getSwiperOptionAttributes(controlOptions, controls)

      options.thumbs.swiper = new Swiper(controls, controlOptions)
    }

    // init slider
    new Swiper(el, options)
  })
}

export function swiperMobile () {
  // mobile sliders, like logo rows etc
  // need to loop in order to get the slide count
  var mobileSwipers = document.querySelectorAll('.swiper-container.mobile')
  mobileSwipers.forEach(function (el, index) {
    var closestSection = el.closest('section')
    var slideCount = el.querySelectorAll('.swiper-slide').length

    var options = {
      speed: 600,
      slidesPerView: 2,
      watchOverflow: true,
      loop: true,
      simulateTouch: false,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        576: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 4
        },
        992: {
          slidesPerView: slideCount
        }
      }
    }

    // init nav
    options.navigation = {
      nextEl: closestSection.querySelector('.swiper-next'),
      prevEl: closestSection.querySelector('.swiper-prev')
    }

    options = getSwiperOptionAttributes(options, el)

    // init slider
    swiper = new Swiper(el, options)
  })
}
