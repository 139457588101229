export function modal() {


	// Trigger the cart modal
	$(document).on("click", "[data-product]", function (e) {
		e.preventDefault();
		var product = $(this).attr("data-product");
		$("body").addClass("cart-active");

		$('.wcf-qty-options input[value="' + product + '"]')
			.prop("checked", true)
			.change();
	});








	// Trigger the ebook modal
	$(document).on("click", "a[href='#modal']", function (e) {
		e.preventDefault();
		$("body").addClass("modal-active");
        
	});

	// Trigger closing any modal
	$('body').on('touchstart mousedown', function (event) {
		console.log(event.target);
		if ($(event.target).is('.js-close') || $(event.target).is('.js-modal') || $(event.target).is('.cancel')) {
			$('body').removeClass('modal-active');
            $('body').removeClass('cart-active');
            $('body').removeClass('discount-modal-active');
		}
	})


    // $("body").addClass("discount-modal-active");

}