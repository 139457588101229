import {accordion} from './components/accordion';
import {swiperDesktop, swiperMobile} from './components/swiper';
import {hamburger, mobileHeader, stickyHeader} from './components/header';
import { viewAnimation, smoothScroll } from "./util/animation";
import {outputMonth, countDown} from './util/dates';
import {inlineVideo} from './util/video';
import {phoneConcat, readMore} from './util/text';
import { cartModal } from "./components/cartflows";
import { modal } from "./components/modal";
// import {cf7Redirect} from './util/window';


(function($) {


    $(window).on('load', function () {
        mobileHeader();
        stickyHeader();
        hamburger();
        outputMonth();
        viewAnimation();
        accordion();
        swiperDesktop();
        swiperMobile();
        smoothScroll();
        inlineVideo();
        phoneConcat();
        cartModal();
        modal();
        countDown();
    });


})( jQuery );
