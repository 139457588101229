export function outputMonth() {
        var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        var now       = new Date();
        var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
        var output = document.getElementsByClassName('output');
    
        if( output.length ) {
    
            for (var i = 0; i < output.length; i++) {
                output[i].innerHTML = thisMonth;
            }
    
        }
}
export function countDown() {
    
    // Get all the countdown timers
    var countdown = document.querySelectorAll('.countdown');

    Array.from(countdown).forEach(function(counter,i){

        // Get the countdown date from the timer
        var countDownDate = new Date(counter.dataset.countdownToDate);

        // Update the count down every 1 second
        var x = setInterval(function () {
            updateCountdown(getTimeLeft(countDownDate), counter);
        }, 1000);

    });

    // Print out the countdown timer
    function updateCountdown(timeObj, counter) {
        var printTime = `<div class="time time__hours"><em>${timeObj.days}</em><span>Days</span></div>
                        <div class="time time__hours"><em>${timeObj.hours}</em><span>Hours</span></div>
                        <div class="time time__hours"><em>${timeObj.minutes}</em><span>Minutes</span></div>`;
        // If the counter says no seconds, don't print them
        if (!counter.classList.contains("countdown--no-seconds")) {
            printTime += `<div class="time time__hours"><em>${timeObj.seconds}</em><span>Seconds</span></div>`;
        }
        // Print out the countdown timer
        counter.innerHTML = printTime;
    }

    // Calculate the time left on the countdown timer
    function getTimeLeft(countDownDate) {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        var result = {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        } 
        
        // // If the count down is over, write some text
        // if (distance < 0) {
        //     clearInterval(x);
        //     countdown.innerHTML = "EXPIRED";
        // }

        return result;
    }
    




  // if ($('.clockdiv').length > 0) {
  //     function getTimeRemaining(endtime) {
  //         const total = Date.parse(endtime) - Date.parse(new Date());
  //         const seconds = Math.floor((total / 1000) % 60);
  //         const minutes = Math.floor((total / 1000 / 60) % 60);
  //         const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  //         const days = Math.floor(total / (1000 * 60 * 60 * 24));

  //         return {
  //           total,
  //           days,
  //           hours,
  //           minutes,
  //           seconds
  //         };
  //       }

  //       function initializeClock(elm, endtime) {
  //         const clock = elm;
  //         const dayReaders = document.querySelectorAll('.remaining-days');
  //         const dayReadersEvent = document.querySelectorAll('.remaining-days-event');
  //         const daysSpan = clock.querySelector('.days');
  //         const hoursSpan = clock.querySelector('.hours');
  //         const minutesSpan = clock.querySelector('.minutes');
  //         const secondsSpan = clock.querySelector('.seconds');

  //         function updateClock() {
  //           const t = getTimeRemaining(endtime);
  //           const te = getTimeRemaining('26 Jan 2022 08:00:00 GMT-7');

  //           if( t.days == 0 && clock.querySelector('.days-wrap')) {
  //             clock.querySelector('.days-wrap + .divider').remove();
  //               clock.querySelector('.days-wrap').remove();
  //           } else {
  //             daysSpan.innerHTML = t.days;
  //           }

  //           for(var i = 0; i < dayReaders.length; i++) {
  //             dayReaders[i].innerHTML = t.days;
  //           }

  //           for(var i = 0; i < dayReadersEvent.length; i++) {
  //             dayReadersEvent[i].innerHTML = te.days;
  //           }

  //           if( t.days == 0 && t.hours == 0 && clock.querySelector('.hours-wrap')) {
  //             clock.querySelector('.hours-wrap + .divider').remove();
  //             clock.querySelector('.hours-wrap').remove();
  //           } else{
  //             hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
  //           }
  //           if( t.days == 0 && t.hours == 0 && t.minutes == 0 && clock.querySelector('.minutes-wrap')){
  //             clock.querySelector('.minutes-wrap + .divider').remove();
  //             clock.querySelector('.minutes-wrap').remove();
  //           } else{
  //             minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
  //           }
  //           secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

  //           if (t.total <= 0) {
  //             clearInterval(timeinterval);
  //             /**
  //              *  Bust the cache on reload so we have the correct prices showing.
  //              */
  //             window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname + '?timer=reloaded';
  //           }
  //         }

  //         updateClock();
  //         const timeinterval = setInterval(updateClock, 1000);
  //       }

  //       var clocks = document.getElementsByClassName('clockdiv');

  //       for(var i = 0; i < clocks.length; i++) {
  //         const deadline = clocks[i].dataset.endtime;
  //         if( deadline ) {
  //           initializeClock(clocks[i], deadline);
  //         }
  //       }
  // }
}