export function viewAnimation () {
  // Components loading animations
  $('.view-animation').viewportChecker({
    classToAdd: 'animated',
    offset: 20
  })
}



export function smoothScroll() {
  $(document).on("click", 'a[href*="#"]', function (event) {
    var href = $(this).attr("href");
    var target = href;

    if (href.includes("/")) {
      if (href.includes(window.location.pathname)) {
        target = "#" + href.split("#")[1];
      } else {
        return;
      }
    }
    event.preventDefault();

    if ($(target).length) {
      $("html, body").animate(
        {
          scrollTop: $(target).offset().top - $(".site-header").outerHeight(),
        },
        1500
      );
    }
  });
}