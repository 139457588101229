export function cartModal() {

	/**
	 *  This hides the Beginners day prompt if they've chosen it as their primary product.
	 */

	$(document.body).on("updated_cart_totals", function () {

		// Maintain the order bump if it is checked on updated cart
		var isBumpChecked = $(".wcf-bump-order-cb").prop("checked");

		// If the beginner only session is checked
		if ($('.wcf-qty-options input[type="radio"]:checked').val() == "540") {
			// And warning doesn't exist already
			if ($(".wcf-product-option-wrap .warn").length <= 0) {

				// Add the warning
				$(".wcf-qty-options").after(
					'<p class="warn">Are you sure that you only want to attend the Beginner Session?</p>'
				);
			}

			// Make sure the bump isn't checked
			$(".wcf-bump-order-cb")
			.prop("checked", false)
			.change();

			// And hide the bump
			$(".wcf-bump-order-content").hide();

		} 
		// If the beginner only session is not checked
		else if ($(".wcf-product-option-wrap .warn").length > 0) {
			
			// Remove the warning
			$(".wcf-product-option-wrap .warn").remove();
			
			// Show the order bump
			if ($(".wcf-bump-order-content").is(":hidden")) {
				$(".wcf-bump-order-content").show();
			}
			
		}
		
	});





	// Fixes select2 ruining scroll after exit
	$(document).on('select2:select', 'select', function () {
		console.log('selectfix');
		const select2 = $(this).next('.select2');
		const id = $(this).attr('id');

		$.fn.select2.amd.require(['select2/utils'], function (Utils) {
			const $watchers = select2.parents().filter(Utils.hasScroll);
			$watchers.off('scroll.select2.select2-' + id);
		});
	});
}

